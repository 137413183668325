import PropTypes from 'prop-types'
import React from 'react'
// 3rd Party Packages
import { RecoilRoot } from 'recoil'

function Provider({ children }) {
  return <RecoilRoot>{children}</RecoilRoot>
}

Provider.propTypes = {
  children: PropTypes.any
}

export default Provider
