exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-shilajit-blogs-js": () => import("./../../../src/pages/blogs/shilajit-blogs.js" /* webpackChunkName: "component---src-pages-blogs-shilajit-blogs-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-collections-all-js": () => import("./../../../src/pages/collections/all.js" /* webpackChunkName: "component---src-pages-collections-all-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pages-algemene-voorwaarden-general-terms-js": () => import("./../../../src/pages/pages/algemene-voorwaarden-general-terms.js" /* webpackChunkName: "component---src-pages-pages-algemene-voorwaarden-general-terms-js" */),
  "component---src-pages-pages-contact-js": () => import("./../../../src/pages/pages/contact.js" /* webpackChunkName: "component---src-pages-pages-contact-js" */),
  "component---src-pages-pages-cookie-policy-js": () => import("./../../../src/pages/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-pages-cookie-policy-js" */),
  "component---src-pages-pages-covid-19-order-update-js": () => import("./../../../src/pages/pages/covid-19-order-update.js" /* webpackChunkName: "component---src-pages-pages-covid-19-order-update-js" */),
  "component---src-pages-pages-faqs-js": () => import("./../../../src/pages/pages/faqs.js" /* webpackChunkName: "component---src-pages-pages-faqs-js" */),
  "component---src-pages-pages-over-ons-about-us-js": () => import("./../../../src/pages/pages/over-ons-about-us.js" /* webpackChunkName: "component---src-pages-pages-over-ons-about-us-js" */),
  "component---src-pages-pages-payment-js": () => import("./../../../src/pages/pages/payment.js" /* webpackChunkName: "component---src-pages-pages-payment-js" */),
  "component---src-pages-pages-privacy-js": () => import("./../../../src/pages/pages/privacy.js" /* webpackChunkName: "component---src-pages-pages-privacy-js" */),
  "component---src-pages-pages-refund-policy-js": () => import("./../../../src/pages/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-pages-refund-policy-js" */),
  "component---src-pages-pages-shipping-returns-js": () => import("./../../../src/pages/pages/shipping-returns.js" /* webpackChunkName: "component---src-pages-pages-shipping-returns-js" */),
  "component---src-pages-pages-terms-of-service-js": () => import("./../../../src/pages/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-pages-terms-of-service-js" */),
  "component---src-pages-pages-terms-of-use-js": () => import("./../../../src/pages/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-pages-terms-of-use-js" */),
  "component---src-templates-all-blog-posts-js": () => import("./../../../src/templates/allBlogPosts.js" /* webpackChunkName: "component---src-templates-all-blog-posts-js" */),
  "component---src-templates-single-blog-post-template-js": () => import("./../../../src/templates/singleBlogPostTemplate.js" /* webpackChunkName: "component---src-templates-single-blog-post-template-js" */),
  "component---src-templates-single-product-template-js": () => import("./../../../src/templates/singleProductTemplate.js" /* webpackChunkName: "component---src-templates-single-product-template-js" */)
}

