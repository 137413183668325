import React from 'react'

// Import Global Styles
import './src/styles/styles.css'
// Import Google Fonts
import './src/styles/google-fonts.css'
// Import Nav Styles
import './src/styles/nav.css'
// Import Blog Body Styles
import './src/styles/blog-body.css'
// Import Simplebar React Styles
import 'simplebar-react/dist/simplebar.min.css'
// Import Page Progress Bar
import './src/styles/page-progress-bar.css'

// Import Provider
import Provider from './src/provider'

export const wrapRootElement = ({ element }) => <Provider>{element}</Provider>
